<template>
  <span class="log-add-button">
    <iframe
      v-if="siteplanLink"
      width="100%"
      height="800px"
      :src="iframeSrc"
    ></iframe>
    <v-btn
      v-if="hasRolesOrPermissions('website_edit') && siteplanLinkEditable"
      fab
      bottom
      right
      dark
      fixed
      :color="editing ? 'blue' : 'red'"
      @click="editing = !editing"
    >
      <v-icon>{{ editing ? "fas fa-chevron-left" : "edit" }}</v-icon>
    </v-btn>
  </span>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("website");

export default {
  name: "websites-siteplan",
  data: () => ({
    editing: false
  }),
  computed: {
    ...mapGetters(["siteplanLink", "siteplanLinkEditable"]),
    iframeSrc() {
      return this.editing ? this.siteplanLinkEditable : this.siteplanLink;
    }
  },
  methods: {
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  }
};
</script>
